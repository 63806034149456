import { useCallback } from "react";

import { apiClient } from "@Models";

import { SingleStringPicker, SingleStringPickerProps } from "../Picker";

type AttachmentCategoryPickerProps = SingleStringPickerProps & {
    loadDataParams?: {
        objectUniqueId?: string;
    }
}

export const AttachmentCategoryPicker: React.FC<AttachmentCategoryPickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.enumAttachmentCategoryGet.bind(apiClient), []);

    return (
        <SingleStringPicker
            {...props}
            loadData={loadData}
        />
    );
}
